import React from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const Home = () => {
    const history = useHistory();
    const { oktaAuth, authState } = useOktaAuth();

//    if (authState.isPending) return null;

    const login = async () => history.push('/login');

    const logout = async () => oktaAuth.signOut();

    const button = authState?.isAuthenticated ?
        <button className="btn mb-3 btn-warning btn-lg btn-block" onClick={logout}>Logout</button> :
        <button className="btn mb-3 btn-success btn-lg btn-block" onClick={login}>Login</button>;

    return (
        <div>

            <div className="row mt-5">
                <div className="col-12">
                    <h2>Welcome</h2>

                    <div className="jumbotron jumbotron-fluid bg-dark">
                        <div className="container">
                            <p className="lead">To change settings on your website, please choose from one of the menu items above.
                                When you see the <i className="fab fa-markdown"/> icon under a text area, it means styling with markdown is supported.
                            This allows you to add blank lines, headings, links, and other styling options. see the <a href="https://guides.github.com/features/mastering-markdown/" rel="noreferrer" target="_blank">Markdown</a> guide for more</p>
                        </div>
                    </div>
                </div>
            </div>

            {button}
        </div>
    );
};
export default Home;
